/* Change this file to get your personal Porfolio */

const greeting = {
  /* Your Summary And Greeting Section */
  title: "Hi all 👋 I'm Ensar",
  subTitle:
    "A passionate Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / React / Python / Django and some other cool libraries and frameworks",
  resumeLink: "https://drive.google.com/file/d/1pbV9l6NvKGz0KGd6zCc3oCAPW_twuNzJ/view?usp=sharing"
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/ensardogrusoz",
  linkedin: "https://www.linkedin.com/in/ensar-dogrusoz/",
  gmail: "kaandogrusoz00@gmail.com"
};

const skillsSection = {
  /* Your Skills Section  */
  title: "What I Do",
  subTitle: "SOFTWARE DEVELOPER WHO WANT TO EXPLORE EVERY TECH STACK",
  skills: [

  ],

  /* Make Sure You include correct Font Awesome Classname to view your icon */
  /* https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    // {
    //   skillName: "sass",
    //   fontAwesomeClassname: "fab fa-sass"
    // },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    // {
    //   skillName: "nodejs",
    //   fontAwesomeClassname: "fab fa-node"
    // },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },{
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    // {
    //   skillName: "aws",
    //   fontAwesomeClassname: "fab fa-aws"
    // },
    // {
    //   skillName: "firebase",
    //   fontAwesomeClassname: "fas fa-fire"
    // },
    
  ]
};

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "HTML/CSS",
      progressPercentage: "95%"
    },
    {
      Stack: "Python",  //Insert stack or technology you have experience in
      progressPercentage: "90%"  //Insert relative proficiency in percentage
    },
    {
      Stack: "Javascript",
      progressPercentage: "80%"
    },
    {
      Stack: "React",
      progressPercentage: "80%"
    },
    {
      Stack: "Java",
      progressPercentage: "60%"
    },
    {
      Stack: "Swift",
      progressPercentage: "50%"
    }
    
  ]
};

const openSource = {
  /* Your Open Source Section to View Your Github Pinned Projects */
  /* To know how to get github key look at readme.md */

  githubConvertedToken: "MDkxNDUyYjNmNzY5MzczY2I1MDEyODUxY2FkM2I1MTE3OTExMTM3Nw==",
  githubUserName: "ensardogrusoz"
};

// const bigProjects = {
//   /* Some Big Projects You have worked with your company */
//   title: "Big Projects",
//   subtitle: "SOME STARTUPS AND COMPANIES THAT I HELP TO CREATE THEIR TECH",
//   projects: [
//     {
//       image: "https://drive.google.com/uc?id=1exWn9T6j8TsfDDHJnS3VR66eP6RiGAfY",
//       link: "http://saayahealth.com/"
//     },
//     {
//       image: "http://nextu.se/wp-content/themes/nextu/images/og_img.jpg",
//       link: "http://nextu.se/"
//     }
//   ]
// };

const achievementSection = {
  /* Your Achievement Section Include Your Certification Talks and More */

  title: "Achievements And Certifications 🏆 ",
  subtitle: "Achievements, Certifications Award Letters and Some Cool Stuff that i have done !",

  achivementsCards: [
    {
      title: "Google CSSI",
      description: "selected to be apart of Google CSSI first cohort at Queens College, a intensive 4-week programming boot camp with Google engineers",
      image:
        "https://edu.google.com/images/resources/programs/computer-science-summer-institute.jpg",
      footerLink: [
        { name: "Certification", url: "https://i.ibb.co/bdcSFzT/PSX-20201017-223740.jpg" },
        { name: "Google x Queens College Blog", url: "https://cssi.qc.cuny.edu/" }
      ]
    },
    {
      title: "Capital One Software Engineering Summit",
      description: "One of 60 students chosen from a pool of 140 applicants to attend Capital One’s Software Engineering Summit in Mclean, Virginia",
      image: "https://pbs.twimg.com/profile_images/1255533617100525574/0Wj6vq0q_400x400.jpg",
      footerLink: [{ name: "", url: "" }]
    
      
    }
  ]
};

const blogSection = {
  /* Blogs Section */

  title: "Projects",
  subtitle: "View some of my awesome projects",

  blogs: [
    {
      url: "https://ensardogrusoz.github.io/covid-19-tracker/",
      image: "https://camo.githubusercontent.com/7687368a620f51ff50ec8b4a9e7adb9d05f08e7e/68747470733a2f2f692e6962622e636f2f583837427156592f53637265656e73686f742d323032302d30342d31332d61742d31302d31342d35382e706e67",
      title: "Covid-19 Tracker",
      description: "Track Covid-19 cases all around the world. Get real time numbers and graphs. Created with React "
    },
    {
      url: "https://jtriviasearch.herokuapp.com/",
      image: "https://i.ibb.co/4s9Wtn6/Screen-Shot-2020-10-17-at-8-36-05-PM.png",
      title: "Jeopardy Game",
      description: "Find trivia questions by category, time, and difficulty. Click 'Random Question' to get a random card. Created with Python/Django."
    },
    {
      url: "https://github.com/ensardogrusoz/CapitalOne-SES-Hackathon",
      image: "https://i.ibb.co/zxxPs0B/rsz-screen-shot-2020-10-17-at-81441-pm-1.jpg",
      title: "MaxBack",
      description: "Fullstack project for the hackathon at the Capital One Software Engineering Summit. Allows users to organize and maximize their credit cards cashback rewards. our app goes through their past transactions and recommends which credit card to use for their purchase in order to maximize rewards"
    },
    {
      url: "https://github.com/ensardogrusoz/Flix", 
      image: "https://i.ibb.co/4KZjTPr/rsz-screen-shot-2020-10-17-at-92337-pm-1.jpg",
      title: "Flix",
      description: "Flix is an app that allows users to browse movies from the The Movie Database API."
    }
  ]

};


// Talks Sections

// const talkSection = {
//   title: "TALKS",
//   subtitle: "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅",

//   talks: [
//     {
//       title: "Build Actions For Google Assistant",
//       subtitle: "Codelab at GDG DevFest Karachi 2019",
//       slides_url: "https://bit.ly/saadpasta-slides",
//       event_url: "https://www.facebook.com/events/2339906106275053/",
//       image:
//         "https://scontent.fkhi6-1.fna.fbcdn.net/v/t1.0-9/76714032_1730516240415559_1293494289556307968_o.jpg?_nc_cat=103&_nc_ohc=s5f81rdZd6wAQmqUa52YQBA21MjVOy7e-HN9jI7MspRDC-v5ahEouyInQ&_nc_ht=scontent.fkhi6-1.fna&oh=c29141a9a0de7ff2011a8191f5475a78&oe=5E83127C"
//     }
//   ]
// };

// Podcast Section
// const podcastSection = {
//   title: "Podcast 🎙️",
//   subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

//   // Please Provide with Your Podcast embeded Link
//   podcast: ["https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"]
// };

const contactInfo = {
  title: "Contact Me",
  subtitle: "Discuss a project or just want to say hi my inbox is open for all",
  email_address: "kaandogrusoz00@gmail.com"
};

export { greeting, socialMediaLinks, skillsSection, techStack, openSource,  achievementSection, blogSection,  contactInfo };
